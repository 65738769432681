import React from "react";
import { graphql } from "gatsby";
import { Article } from "../models/article.model";
import PageWrapper from "../components/PageWrapper";
import logo from "../matrix-logo.svg";
import bospar from "../images/bospar.png";
import { ROUTE_LINKS } from "../utils/routes";

const NewsArticle03 = (props: any) => {
  const article: Article = props.data.article.frontmatter;

  return (
    <PageWrapper path={ROUTE_LINKS.news}>
      <main className="flex flex-col max-w-6xl md:mx-auto px-2.5 py-12">
        <div className="h-8 sm:h-12 md:h-20 mb-5">
          <img
            src={bospar}
            className="object-cover object-center h-full"
            alt="Logo"
          />
        </div>
        <div className="flex items-center justify-center h-12 sm:h-20 md:h-36 mb-5">
          <img
            src={logo}
            className="object-cover object-center h-full"
            alt="Logo"
          />
        </div>
        <h2 className="font-bold text-xl mb-10 text-center">
          Matrix Rental Solutions awarded company of the year by Stamford
          Chamber of Commerce at 36th Annual Meeting and Awards Ceremony
        </h2>
        <p className="italic font-light text-center text-lg mb-5">
          The recognition highlights outstanding business breakthroughs,
          including Matrix's innovative approach to democratizing the rental
          housing market.
        </p>
        <p className="mb-5">
          <strong>STAMFORD, Conn.</strong>&nbsp;––&nbsp;August 17,
          2023&nbsp;––&nbsp;
          <a
            className="underline text-blue-500"
            href="https://mymatrixrent.com/"
          >
            Matrix Rental Solutions
          </a>
          , an innovative tenant analysis fintech platform, has been selected to
          receive&nbsp;
          <a
            className="underline text-blue-500"
            href="https://www.stamfordchamber.com/"
          >
            the Stamford Chamber of Commerce's
          </a>
          &nbsp;prestigious "Company of the Year" award. The award will be
          presented at the 36th Annual Meeting and Awards Ceremony on Thursday,
          September 28th. The event will kick off with welcome remarks from
          Mayor Caroline Simmons followed by a keynote address by Governor Ned
          Lamont. The "Company of the Year" recognition highlights outstanding
          business breakthroughs, including Matrix's innovative approach to
          democratizing the rental housing market, with an aim toward
          facilitating affordable housing for all.
        </p>
        <p className="mb-5">
          Founded by Sipho Simela, Matrix Rental Solutions addresses the
          critical issue of housing affordability through its proprietary tenant
          scoring model, MAIA (Matrix Asset & Income Analysis). MAIA enables
          landlords and property managers to underwrite and approve tenants in
          under 15 minutes, significantly streamlining tenant screening
          processes.
        </p>
        <p className="mb-5">
          "We're propelled by the belief that housing supply and affordability
          are pivotal economic challenges facing our state and country today,"
          said Sipho Simela, founder and CEO of Matrix Rental Solutions. "Our
          Universal Rental Application helps level the playing field, creating a
          win-win scenario for home providers and prospective residents. Our
          mission is to provide housing solutions that foster a sense of
          community and inclusion."
        </p>
        <p className="mb-5">
          The recognition by the Stamford Chamber of Commerce underscores the
          remarkable impact of Matrix on the rental housing market. The
          company's commitment to driving positive change for tenants and
          landlords is reflected in Sipho Simela's appointment to the&nbsp;
          <a
            className="underline text-blue-500"
            href="https://bipartisanpolicy.org/policy-area/housing-terwilliger/"
          >
            Bipartisan Policy Center (BPC) Executive Council on Housing
            Affordability
          </a>
          .
        </p>
        <p className="mb-5">
          “Sipho is an innovative entrepreneur that always searches for change,
          responds to it, and creates opportunities as the business continues to
          grow, potentially making a difference in millions of people’s lives,”
          stated Heather Cavanagh, President and CEO of the Stamford Chamber of
          Commerce.
        </p>
        <p className="mb-5">
          In addition to being recognized as the Company of the Year, Matrix
          Rental Solutions’ CEO Sipho Simela was has previously named to the
          esteemed&nbsp;
          <a
            className="underline text-blue-500"
            href="https://westfaironline.com/40under40/"
          >
            Westfair Business Journal 40 under 40
          </a>
          , further attesting to the company’s rapid rise in the industry.
        </p>
        <p className="mb-5">
          Matrix Rental Solutions continues to expand the boundaries of
          innovation; its dedication to fostering equitable housing solutions
          resonates deeply. With a vision encompassing collaboration and
          progress, Sipho Simela reflects: "If you want to go fast, go alone. If
          you want to go far, go together."
        </p>
        <h5 className="font-bold text-base">Event Details:</h5>
        <p className="mb-5">
          The Stamford Chamber of Commerce's signature event will be hosted
          at&nbsp;
          <a
            className="underline text-blue-500"
            href="https://thelinkstamford.com/"
          >
            The Link located at 200 Elm Street in Stamford
          </a>
          &nbsp;on Thursday, September 28th, from 3:00 PM - 6:00 PM EDT.
          <br />
          The event is sponsored by&nbsp;
          <a className="underline text-blue-500" href="https://www3.mtb.com/">
            M & T Bank
          </a>
          , and the host venue-supporting sponsor is&nbsp;
          <a
            className="underline text-blue-500"
            href="https://www.am-property.net/"
          >
            AM Property Holding Corporation
          </a>
          .<br />
          Register for the event&nbsp;
          <a
            className="underline text-blue-500"
            href="https://www.stamfordchamber.com/"
          >
            HERE
          </a>
        </p>
        <h5 className="mb-5 font-bold text-base">About Matrix</h5>
        <p className="mb-5">
          Matrix is a Universal Rental Application that is changing the
          landscape of the growing, $500 billion rental housing market. Matrix
          features a single, reusable application with a single fee, powered by
          real-time data and producing an “ability to pay” score. Using a
          variety of factors, including assets, income and previous rental
          history, the result is more inclusive scoring that gives property
          managers faster, more accurate insights into the full financial
          profile of a prospective tenant. Matrix removes the potential for
          source of income discrimination, taking the subsidy into consideration
          and automating the underwriting process. Today’s processing time for
          affordable housing applications is approximately 36 days. This leads
          to compounding losses, making public housing acceptance simply not an
          option for many owners. Matrix streamlines the acceptance process and
          shortens it by up to 50%, helping more owners make their properties
          available to affordable housing and get leased faster. For more
          information, please visit&nbsp;
          <a
            className="underline text-blue-500"
            href="https://www.mymatrixrent.com/"
          >
            https://www.mymatrixrent.com
          </a>
          .
        </p>
        <h5 className="mb-5 font-bold text-base">PR Contact:</h5>
        <p>
          Caroline Kamerschen
          <br />
          770-712-2636
          <br />
          <a className="underline text-blue-500" href="caroline@bospar.com">
            caroline@bospar.com
          </a>
        </p>
      </main>
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query($slug: Int) {
    article: markdownRemark(
      frontmatter: { slug: { eq: $slug } }
      fields: { category: { eq: "articles" } }
    ) {
      frontmatter {
        title
        date
        url
      }
    }
  }
`;

export default React.memo(NewsArticle03);
